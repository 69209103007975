@mixin btnStyle($background, $border, $color: map-get(map-get($palette, light), 1)) {
    @if ($background) {
        background: $background;
    }
    @if ($border) {
        border: $border;
    }
    color: $color;
}

.p-button {
    @include btnStyle(map-get(map-get($palette, primary), 4), $border-thin map-get(map-get($palette, primary), 4));

    &:enabled:hover {
        @include btnStyle(map-get(map-get($palette, primary), 4), $border-thin map-get(map-get($palette, primary), 4));
    }

    &.p-button-primary {
        @include btnStyle(map-get(map-get($palette, primary), 3), $border-thin map-get(map-get($palette, primary), 3));

        &:enabled:hover {
            @include btnStyle(map-get(map-get($palette, primary), 3), $border-thin map-get(map-get($palette, primary), 3));
        }

        &.p-button-text {
            @include btnStyle(map-get(map-get($palette, light), 1), 0, map-get(map-get($palette, primary), 3));

            &:enabled:hover {
                @include btnStyle(map-get(map-get($palette, light), 1), 0, map-get(map-get($palette, primary), 3));
            }
        }
    }

    &.p-button-danger {
        @include btnStyle(map-get(map-get($palette, danger), 1), $border-thin map-get(map-get($palette, danger), 1));

        &.light {
            @include btnStyle(map-get(map-get($palette, danger), 3), $border-thin map-get(map-get($palette, danger), 3));

            &:enabled:hover {
                @include btnStyle(map-get(map-get($palette, danger), 3), $border-thin map-get(map-get($palette, danger), 3));
            }
        }
    }

    &.p-button-secondary {
            @include btnStyle(map-get(map-get($palette, secondary), 9), $border-thin map-get(map-get($palette, secondary), 9), map-get(map-get($palette, primary), 3));

        &:enabled:hover {
            @include btnStyle(map-get(map-get($palette, secondary), 9), $border-thin map-get(map-get($palette, secondary), 9), map-get(map-get($palette, primary), 3));
        }


        &.p-button-outlined {
            @include btnStyle(
                map-get(map-get($palette, light), 1),
                $border-thin map-get(map-get($palette, secondary), 2),
                map-get(map-get($palette, secondary), 7)
            );

            &:enabled:hover {
                background: map-get(map-get($palette, light), 1);
            }
        }
    }

    &.p-button-success {
        @include btnStyle(map-get(map-get($palette, success), 1), $border-thin map-get(map-get($palette, success), 1));
    }
    
}