:root {
	--surface-border: #EBEFF2;
    --primary-color: #2C71C3;
}

.p-button-label {
    font-weight: 400;
}

// TOAST ALERT 
.p-toast {
    .p-toast-message {
        box-shadow: 0px 4px 4px 0px #00000040;

        .p-toast-message-content {
            .p-toast-detail {
                margin-top: 0;
            }
        }

        &.p-toast-message-success {
            background: #fff;
            border: solid #57A682;
            color: #57A682;
            border-width: 0 0 0 30px;

            .p-toast-icon-close, .p-toast-message-icon {
                color: #57A682;
            }
        }

        &.p-toast-message-error {
            background: #fff;
            border: solid #C33D3D;
            color: #C33D3D;
            border-width: 0 0 0 30px;

            .p-toast-icon-close, .p-toast-message-icon {
                color: #C33D3D;
            }
        }
    }
}
// ----------------------------------------------------------------

// TABLE
.p-datatable {
    .p-datatable-table {
        .p-datatable-tbody {
            tr {
                cursor: pointer;
            }
        }
    }
    &.no-pointer {
        .p-datatable-table {
            .p-datatable-tbody {
                tr {
                    cursor: unset;
                }
            }
        }
    }
}
// ----------------------------------------------------------------

// CALENDAR
.p-datepicker table td > span.p-highlight {
    color: #2C71C3;
    background: #d6dde7;
}
// -----------------------------------------------------------------

// MULTI SELECT
.p-multiselect-panel {
    .p-multiselect-items {
        .p-multiselect-item {
            &.p-highlight {
                color: map-get(map-get($palette, primary), 3);
            }
        }
    }
}
// ------------------------------------------------------------------

// DROPDOWN
.p-dropdown-panel {
    .p-dropdown-item {
        padding: 0.5rem 1.25rem !important;
        font-size: 0.875rem;
        &.p-highlight {
            color: map-get(map-get($palette, primary), 3) !important;
        }
    }
}
// -------------------------------------------------------------------

// INPUT
.p-input-icon-right > .p-inputtext {
    padding-right: 0.75rem;
}