@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;400;500;600;800;900&display=swap");
* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}
*:active, *:hover, *:focus {
  outline: 0;
}

html {
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 13px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #192a3e !important;
  text-align: left;
  height: 100%;
}

.p-component {
  font-family: "Poppins", sans-serif !important;
}

.custom-marker {
  position: absolute;
  z-index: 100000000;
}
.custom-marker .hand-banner {
  min-width: 160px;
  width: 160px;
  position: relative;
}
.custom-marker .rectangle {
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.custom-marker .rectangle img {
  height: 30px;
  width: 30px;
}
.custom-marker .pipe {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 6px;
  height: 30px;
  background-color: #333333;
  border-radius: 0px 0px 10px 10px;
}

:root {
  --surface-border: #EBEFF2;
  --primary-color: #2C71C3;
}

.p-button-label {
  font-weight: 400;
}

.p-toast .p-toast-message {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin-top: 0;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #fff;
  border: solid #57A682;
  color: #57A682;
  border-width: 0 0 0 30px;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close, .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  color: #57A682;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #fff;
  border: solid #C33D3D;
  color: #C33D3D;
  border-width: 0 0 0 30px;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close, .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon {
  color: #C33D3D;
}

.p-datatable .p-datatable-table .p-datatable-tbody tr {
  cursor: pointer;
}
.p-datatable.no-pointer .p-datatable-table .p-datatable-tbody tr {
  cursor: unset;
}

.p-datepicker table td > span.p-highlight {
  color: #2C71C3;
  background: #d6dde7;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #334d6e;
}

.p-dropdown-panel .p-dropdown-item {
  padding: 0.5rem 1.25rem !important;
  font-size: 0.875rem;
}
.p-dropdown-panel .p-dropdown-item.p-highlight {
  color: #334d6e !important;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 0.75rem;
}

.color-danger {
  color: #d42f2f;
}

.color-primary {
  color: #192a3e;
}
.color-primary.v2 {
  color: #2c71c3;
}
.color-primary.v3 {
  color: #334d6e;
}

.color-sky {
  color: #2c71c3;
}

.color-secondary {
  color: #828282;
}
.color-secondary.v1 {
  color: #666666;
}
.color-secondary.v2 {
  color: #6a707e;
}
.color-secondary.v7 {
  color: #707683;
}
.color-secondary.v8 {
  color: #99A6B7;
}

.color-transparent {
  color: transparent !important;
}

a.dark {
  color: #192a3e;
}

.text-xxs {
  font-size: 0.5rem !important;
}

.p-checkbox.sm {
  width: 15px;
  height: 15px;
}
.p-checkbox.sm .p-checkbox-box {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border: 1px solid #828282;
}
.p-checkbox.sm .p-checkbox-box.p-highlight {
  border: 0;
}

.p-radiobutton.sm .p-radiobutton-box {
  width: 16px;
  height: 16px;
  border: 1px solid #828282;
}
.p-radiobutton.sm .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  width: 6px;
  height: 6px;
}

.form-control {
  font-size: 0.875rem;
}
.form-control.p-dropdown .p-dropdown-label {
  padding: 0 0 0 0.5rem;
  font-size: 0.875rem;
}
.form-control.p-dropdown .p-dropdown-panel .p-dropdown-items {
  padding: 0.25rem 0;
}
.form-control.p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}
.form-control.p-dropdown .p-dropdown-empty-message {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
}
.form-control.p-dropdown.text-xs .p-dropdown-label {
  font-size: 0.75rem;
}
.form-control.p-dropdown.text-xs .p-dropdown-label .p-dropdown-items .p-dropdown-item {
  font-size: 0.75rem;
}
.form-control.p-dropdown.text-xs .p-dropdown-empty-message {
  font-size: 0.75rem;
}
.form-control.p-multiselect .p-multiselect-label-container .p-multiselect-label {
  padding: 0;
}

.p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter {
  padding: 0.5rem;
}

.multi-dropdown-panel .p-multiselect-filter-container .p-multiselect-filter {
  padding: 0.25rem 0.75rem;
}
.multi-dropdown-panel .p-checkbox-box {
  border: 1px solid #ced4da;
  width: 20px;
  height: 20px;
}
.multi-dropdown-panel .p-checkbox-box.p-highlight {
  border-color: #2c71c3;
  background: #2c71c3;
}

.datefilter input {
  cursor: pointer;
}
.datefilter ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2c71c3;
}
.datefilter :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #2c71c3;
  opacity: 1;
}
.datefilter ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #2c71c3;
  opacity: 1;
}
.datefilter :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2c71c3;
}
.datefilter ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2c71c3;
}
.datefilter ::placeholder {
  /* Most modern browsers support this now. */
  color: #2c71c3;
}

.switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #2c71c3;
}
.switch.p-inputswitch .p-inputswitch-slider {
  height: 19px;
  width: 40px;
}
.switch.p-inputswitch .p-inputswitch-slider::before {
  width: 0.75rem;
  height: 0.75rem;
  margin-top: -0.35rem;
}

.p-button {
  background: #1F8DC6;
  border: 1px solid #1F8DC6;
  color: #ffffff;
}
.p-button:enabled:hover {
  background: #1F8DC6;
  border: 1px solid #1F8DC6;
  color: #ffffff;
}
.p-button.p-button-primary {
  background: #334d6e;
  border: 1px solid #334d6e;
  color: #ffffff;
}
.p-button.p-button-primary:enabled:hover {
  background: #334d6e;
  border: 1px solid #334d6e;
  color: #ffffff;
}
.p-button.p-button-primary.p-button-text {
  background: #ffffff;
  border: 0;
  color: #334d6e;
}
.p-button.p-button-primary.p-button-text:enabled:hover {
  background: #ffffff;
  border: 0;
  color: #334d6e;
}
.p-button.p-button-danger {
  background: #d42f2f;
  border: 1px solid #d42f2f;
  color: #ffffff;
}
.p-button.p-button-danger.light {
  background: #cd9171;
  border: 1px solid #cd9171;
  color: #ffffff;
}
.p-button.p-button-danger.light:enabled:hover {
  background: #cd9171;
  border: 1px solid #cd9171;
  color: #ffffff;
}
.p-button.p-button-secondary {
  background: #F2F0EA;
  border: 1px solid #F2F0EA;
  color: #334d6e;
}
.p-button.p-button-secondary:enabled:hover {
  background: #F2F0EA;
  border: 1px solid #F2F0EA;
  color: #334d6e;
}
.p-button.p-button-secondary.p-button-outlined {
  background: #ffffff;
  border: 1px solid #ebeff2;
  color: #707683;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover {
  background: #ffffff;
}
.p-button.p-button-success {
  background: #198754;
  border: 1px solid #198754;
  color: #ffffff;
}

.tabs-group .p-tabview-panels {
  padding: 0;
}
.tabs-group .p-tabview-nav {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-bottom: 1px solid #ebeff2;
}
.tabs-group .p-tabview-nav li {
  font-size: 0.875rem;
}
.tabs-group .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #2c71c3;
  color: #2c71c3;
}
.tabs-group .p-tabview-nav li.p-highlight .p-tabview-nav-link:focus {
  box-shadow: none !important;
}
.tabs-group .p-tabview-nav li.p-highlight .p-tabview-nav-link .p-tabview-title {
  font-weight: 600;
}
.tabs-group .p-tabview-nav li .p-tabview-nav-link {
  min-width: 85px;
  justify-content: center;
}
.tabs-group .p-tabview-nav li .p-tabview-nav-link .p-tabview-title {
  font-weight: 400;
}
@media only screen and (max-width: 600px) {
  .tabs-group .p-tabview-nav {
    display: inline-flex;
    padding-right: 46px;
  }
}
.tabs-group .tab-filter {
  top: 13px;
  right: 20px;
}
.tabs-group .tab-filter.mobile {
  top: 0;
  right: 0;
  z-index: 1101;
}
.tabs-group .tab-filter.mobile .filter-icon {
  background: #d9d9d9;
  padding: 15px 15px 9px 15px;
}
.tabs-group .tab-filter.mobile .p-dialog-header {
  border-bottom: 1px solid #ebeff2;
}

.tab-action {
  top: 13px;
  right: 20px;
}

.pointer {
  cursor: pointer;
}

.disable-link {
  opacity: 0.6;
  pointer-events: none;
}

.overlay-nav.search .p-overlaypanel-content li:hover {
  background-color: transparent;
  color: #192a3e;
}
.overlay-nav li {
  transition: 0.2s;
}
.overlay-nav li:hover {
  background-color: #cd9171;
  color: #ffffff;
}
.overlay-nav li.danger:hover {
  opacity: 0.8;
  background-color: #d42f2f;
}
.overlay-nav .p-overlaypanel-content {
  padding: 0.25rem 0;
}

.p-tooltip {
  font-size: 0.875rem;
}
.p-tooltip.sky .p-tooltip-text {
  background-color: #88ccdc;
  color: #ffffff;
}

.custom-list-group .custom-label {
  color: #99A6B7;
  font-size: 14px;
  font-weight: 500;
}
.custom-list-group .custom-label-value {
  color: #334d6e;
  font-size: 13px;
  font-weight: 400;
}

hr {
  border-bottom: 0px;
  border-color: rgba(235, 239, 242, 0.3411764706);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.b-0 {
  border: 0;
}

.p-datepicker-trigger .p-button {
  background: transparent;
  border: 0;
  color: #000;
}

.p-calendar-w-btn .p-inputtext {
  border-right: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.pac-container {
  z-index: 9999 !important;
}

.datatable.p-datatable .p-datatable-loading-overlay {
  display: none;
}
.datatable.p-datatable .p-datatable-thead > tr > th {
  background-color: #ffffff;
  color: #334d6e;
}
.datatable.p-datatable .p-datatable-tbody tr td {
  color: #828282;
}
.datatable.p-datatable .no-data {
  min-height: 400px;
}
.datatable.p-datatable .p-paginator {
  justify-content: end;
}
.datatable.p-datatable .p-paginator .p-paginator-current {
  font-size: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-element {
  color: #828282;
  min-width: 2rem;
  height: 2rem;
  font-size: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-element .p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-rpp-options {
  height: 2rem;
  align-items: center;
  font-size: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-rpp-options .p-inputtext {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-rpp-options .p-dropdown-trigger-icon {
  width: 0.875rem;
  height: 0.875rem;
}

.dots-action {
  width: 28px;
  height: 28px;
  background-color: #F2F0EA;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}