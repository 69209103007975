@mixin color($color) {
    color: $color;
}

.color-danger {
    @include color(map-get(map-get($palette, danger), 1));
}

.color-primary {
    @include color(map-get(map-get($palette, primary), 1));

    &.v2 {
        @include color(map-get(map-get($palette, primary), 2));
    }
    
    &.v3 {
        @include color(map-get(map-get($palette, primary), 3));
    }
}

.color-sky {
    @include color(map-get(map-get($palette, primary), 2));
}

.color-secondary {
    @include color(map-get(map-get($palette, secondary), 1));
    &.v1 {
        @include color(map-get(map-get($palette, secondary), 3));
    }
    &.v2 {
        @include color(map-get(map-get($palette, secondary), 4));
    }
    &.v7 {
        @include color(map-get(map-get($palette, secondary), 7));
    }
    &.v8 {
        @include color(map-get(map-get($palette, secondary), 8));
    }
}

.color-transparent {
    color: transparent !important;
}

a {
    &.dark {
        color: map-get(map-get($palette, primary), 1);
    }
}

.text-xxs {
    font-size: 0.5rem !important;
}