.tabs-group {
    .p-tabview-panels {
        padding: 0;
    }

    .p-tabview-nav {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-bottom: $border-thin map-get(map-get($palette, secondary), 2);

        li {
            font-size: 0.875rem;
            &.p-highlight {
                .p-tabview-nav-link {
                    border-color: map-get(map-get($palette, primary), 2);
                    color: map-get(map-get($palette, primary), 2);

                    &:focus {
                        box-shadow: none !important;
                    }

                    .p-tabview-title {
                        font-weight: 600;
                    }
                }
            }

            .p-tabview-nav-link {
                min-width: 85px;
                justify-content: center;
                .p-tabview-title {
                    font-weight: 400;
                }
            }
        }
        
        @media only screen and (max-width: 600px) {
            display: inline-flex;
            padding-right: 46px;
        }
    }

    .tab-filter {
        top: 13px;
        right: 20px;

        &.mobile {
            top: 0;
            right: 0;
            z-index: 1101;

            .filter-icon {
                background: #d9d9d9;
                padding: 15px 15px 9px 15px;
            }

            .p-dialog-header {
                border-bottom: $border-thin map-get(map-get($palette, secondary), 2);
            }
        }
    }
}

.tab-action {
    top: 13px;
    right: 20px;
}