.p-checkbox {
    &.sm {
        width: 15px;
        height: 15px;

        .p-checkbox-box {
            width: 15px;
            height: 15px;
            border-radius: 2px;
            border: $border-thin map-get(map-get($palette, secondary), 1);

            &.p-highlight {
                border: 0;
            }
        }
    }
}

.p-radiobutton {
    &.sm {
        .p-radiobutton-box {
            width: 16px;
            height: 16px;
            border: $border-thin map-get(map-get($palette, secondary), 1);

            &.p-highlight {
                .p-radiobutton-icon {
                    width: 6px;
                    height: 6px;
                }
            }
        }
    }
}

.form-control {
    font-size: 0.875rem;

    &.p-dropdown {
        .p-dropdown-label {
            padding: 0 0 0 0.5rem;
            font-size: 0.875rem;
        }

        .p-dropdown-panel {
            .p-dropdown-items {
                padding: 0.25rem 0;
                .p-dropdown-item {
                    padding: 0.5rem 1rem;
                    font-size: 0.875rem;
                }
            }
        }

        .p-dropdown-empty-message {
            font-size: 0.875rem;
            padding: 0.25rem 0.5rem;
        }

        &.text-xs {
            .p-dropdown-label {
                font-size: 0.75rem;
                .p-dropdown-items {
                    .p-dropdown-item {
                        font-size: 0.75rem;
                    }
                }
            }

            .p-dropdown-empty-message {
                font-size: 0.75rem;
            }
        }
    }

    &.p-multiselect {
        .p-multiselect-label-container {
            .p-multiselect-label {
                padding: 0;
            }
        }
    }
}

.p-dropdown-header {
    .p-dropdown-filter-container {
        .p-dropdown-filter {
            padding: 0.5rem;
        }
    }
}

.multi-dropdown-panel {
    .p-multiselect-filter-container {
        .p-multiselect-filter {
            padding: 0.25rem 0.75rem;
        }
    }

    .p-checkbox-box {
        border: 1px solid #ced4da;
        width: 20px;
        height: 20px;
        
        &.p-highlight {
            border-color: map-get(map-get($palette, primary), 2);
            background: map-get(map-get($palette, primary), 2);
        }
    }
}

.datefilter {
    input {
        cursor: pointer;
    }
    ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: map-get(map-get($palette, primary), 2);
    }
    :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: map-get(map-get($palette, primary), 2);
        opacity: 1;
    }
    ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: map-get(map-get($palette, primary), 2);
        opacity: 1;
    }
    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: map-get(map-get($palette, primary), 2);
    }
    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: map-get(map-get($palette, primary), 2);
    }

    ::placeholder {
        /* Most modern browsers support this now. */
        color: map-get(map-get($palette, primary), 2);
    }
}

.switch {
    &.p-inputswitch {
        &.p-inputswitch-checked {
            .p-inputswitch-slider {
                background: map-get(map-get($palette, primary), 2);
            }
        }

        .p-inputswitch-slider {
            height: 19px;
            width: 40px;
            &::before {
                width: 0.75rem;
                height: 0.75rem;
                margin-top: -0.35rem;
            }
        }
    }
}

