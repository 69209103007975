.datatable {
    &.p-datatable {
        .p-datatable-loading-overlay {
            display: none;
        }
        .p-datatable-thead>tr>th {
            background-color: map-get(map-get($palette, light), 1);
            color: map-get(map-get($palette, primary), 3);
        }

        .p-datatable-tbody {
            tr {
                td {
                    color: map-get(map-get($palette, secondary), 1);
                }
            }
        }

        .no-data {
            min-height: 400px;
        }

        .p-paginator {
            justify-content: end;

            .p-paginator-current {
                font-size: 0.875rem;
            }

            .p-paginator-element {
                color: #828282;
                min-width: 2rem;
                height: 2rem;
                font-size: 0.875rem;

                .p-icon {
                    width: 0.875rem;
                    height: 0.875rem;
                }
            }

            .p-paginator-rpp-options {
                height: 2rem;
                align-items: center;
                font-size: 0.875rem;

                .p-inputtext {
                    padding: 0.25rem 0.75rem;
                    font-size: 0.875rem;
                }

                .p-dropdown-trigger-icon {
                    width: 0.875rem;
                    height: 0.875rem;
                }
            }
        }
    }
}