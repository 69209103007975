.pointer {
    cursor: pointer;
}

.disable-link {
    opacity: 0.6;
    pointer-events: none;
}

.overlay-nav {
    &.search {
        .p-overlaypanel-content {
            li {
                &:hover {
                    background-color: transparent;
                    color: map-get(map-get($palette, primary), 1);
                }
            }
        }
    }

    li {
        transition: 0.2s;
        &:hover {
            background-color: map-get(map-get($palette, danger), 3);
            color: map-get(map-get($palette, light), 1);
        }

        &.danger:hover {
            opacity: 0.8;
            background-color: map-get(map-get($palette, danger), 1);
        }
    }

    .p-overlaypanel-content {
        padding: 0.25rem 0;
    }
}

.p-tooltip {
    font-size: 0.875rem;
    &.sky {
        .p-tooltip-text {
            background-color: #88ccdc;
            color: map-get(map-get($palette, light), 1);
        }
    }
}

.custom-list-group {
    .custom-label {
        color: map-get(map-get($palette, secondary), 8);
        font-size: 14px;
        font-weight: 500;
    }

    .custom-label-value {
        color: map-get(map-get($palette, primary), 3);
        font-size: 13px;
        font-weight: 400;
    }
}

hr {
    border-bottom: 0px;
    border-color: #ebeff257;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.b-0 {
    border: 0;
}

.p-datepicker-trigger {
    .p-button {
        background: transparent;
        border: 0;
        color: #000;
    }
}

.p-calendar-w-btn {
    .p-inputtext {
        border-right: 0;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

// map
.pac-container {
    z-index: 9999 !important;
}