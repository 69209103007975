* {
    box-sizing: border-box;
    &:active,
    &:hover,
    &:focus {
        outline: 0;
    }
    font-family: $poppins;
}

html {
    height: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    font-size: 13px;
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 1.5;
    color: map-get(map-get($palette, primary), 1) !important;
    text-align: left;
    height: 100%;
    // background-color: map-get(map-get($palette, light), 1);
}

.p-component {
    font-family: $poppins;
}

// map
.custom-marker {
    position: absolute;
    z-index: 100000000;
    .hand-banner {
        min-width: 160px;
        width: 160px;
        position: relative;
    }

    .rectangle {
        padding: 8px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        img {
            height: 30px;
            width: 30px;
        }
    }

    .pipe {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        width: 6px;
        height: 30px;
        background-color: #333333;
        border-radius: 0px 0px 10px 10px;
    }
}